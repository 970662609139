import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAllOrganisationsLegacyDocument = gql`
  query GetAllOrganisationsLegacy(
    $filter: RansackFilterType
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $sort: [RansackSortType!]
  ) {
    organisations(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
      searchText: $searchText
      sort: $sort
    ) {
      edges {
        node {
          id
          name
          isASupplier
          isAPurchaser
          legalEntity
          isVerified
          parent {
            id
            name
          }
          createdAt
          owner {
            id
            name
          }
          children {
            totalCount
          }
        }
      }
      pageInfo {
        ...PurchasePlusPageInfo
      }
    }
  }
  ${PurchasePlusPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllOrganisationsLegacyQuery, Types.GetAllOrganisationsLegacyQueryVariables>
