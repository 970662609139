export const ALL_USER_TABLE_NAME = 'all-users-table'
export const ALL_BUY_LIST_TABLE_NAME = 'all-buy-lists'
export const BUY_LIST_PRODUCTS_TABLE_NAME = 'buy-list-products'
export const ALL_CATALOGS_TABLE_NAME = 'all-catalogs'
export const CATALOG_PRODUCTS_TABLE_NAME = 'catalog-products-table'
export const STOCKTAKE_STOCK_COUNT_TABLE_NAME = 'stocktake-stock-count-table'
export const NEW_INVENTORY_STOCKTAKE_STOCK_COUNT_TABLE_NAME = 'new-inventory-stocktake-stock-count-table'
export const ALL_CREDIT_NOTES_TABLE_NAME = 'all-credit-notes'
export const ALL_INVOICES_AWAITING_MY_APPROVAL_TABLE_NAME = 'all-invoices-awaiting-my-approval'
export const ALL_INVOICES_TABLE_NAME = 'all-invoices'
export const ALL_SUPPLIER_INVOICES_TABLE_NAME = 'all-supplier-invoices'
export const CREDIT_NOTE_PRODUCT_SUMMARY_TABLE_NAME = 'creditNote-product-summary'
export const INVOICE_PRODUCT_SUMMARY_TABLE_NAME = 'invoice-product-summary'
export const INVOICE_PRODUCT_ANALYSIS_TABLE_NAME = 'invoice-product-analysis-table'
export const PENDING_INVOICES_TABLE_NAME = 'pending-invoices'
export const SUPPLIER_PRODUCTS_TABLE_NAME = 'supplier-products-table'
export const PURCHASER_PRODUCTS_TABLE_NAME = 'purchaser-products-table'
export const ALL_PURCHASE_ORDERS_TABLE_NAME = 'all-purchase-orders'
export const ALL_SUPPLIER_PURCHASE_ORDERS_TABLE_NAME = 'all-supplier-purchase-orders'
export const ALL_RECEIVING_NOTES_TABLE_NAME = 'all-receiving-notes'
export const RECEIVING_NOTE_RECEIVED_TABLE_NAME = 'receiving-note-received'
export const RECEIVING_NOTE_DRAFT_TABLE_NAME = 'receiving-note-draft'
export const PURCHASE_ORDER_PRODUCT_SUMMARY_TABLE_NAME = 'po-product-summary'
export const ADVANCED_PRODUCT_LIST_TABLE_NAME = 'advanced-product-list'
export const ALL_REQUISITION_TABLE_NAME = 'all-requisitions'
export const REQUISITIONS_AWAITING_MY_APPROVAL_TABLE_NAME = 'requisitions-awaiting-my-approval'
export const REQUISITIONS_DRAFT_TABLE_NAME = 'requisitions-draft'
export const REQUISITION_APPROVERS_TABLE_NAME = 'requisition-approvers-table'
export const REQUISITION_PRODUCT_SUMMARY_TABLE_NAME = 'requisition-product-summary'
export const REQUISITION_PRODUCT_SUMMARY_TABLE_NAME_READONLY = 'requisition-product-summary-readonly'
export const BASIC_SHOPPING_PRODUCTS = 'basic-shopping-products'
export const STOCKTAKE_STOCK_COUNT_MODAL_TABLE_NAME = 'stocktake-stock-count-table-modal'
export const ATTACHMENT_TABLE_NAME = 'attachment-table-view'
export const PURCHASER_DIRECTORY_TABLE_NAME = 'purchaser-directory-table'
export const MY_SUPPLIERS_TABLE_NAME = 'my-suppliers-table'
export const PRODUCT_RULES_TABLE_NAME = 'product-rules-table'
export const SUPPLIER_DIRECTORY_TABLE_NAME = 'supplier-directory-table'
export const SUPPLIER_PUBLIC_PRODUCTS_TABLE_NAME = 'supplier-public-products-table'
export const UTILITIES_PROCESS_PRODUCTS_TABLE_NAME = 'utilities-process-products-table'
export const UTILITIES_PROCESS_PRODUCTS_MATCHING_TABLE_NAME = 'utilities-process-products-matching-table'
export const UTILITIES_PROCESS_PRODUCTS_POSSIBLE_MATCH_TABLE_NAME = 'utilities-process-products-possible-match-table'
export const UTILITIES_SEARCH_PRODUCTS_TABLE_NAME = 'utilities-search-products-table'
export const UTILITIES_SEARCH_PRODUCTS_FILTER_TABLE_NAME = 'utilities-search-products-filter-table'
export const ELECTRONIC_DESTINATION_TABLE_NAME = 'electronic-destination-table'
export const CATALOG_IMPORTS_TABLE_NAME = 'catalog-imports-table'
export const CATALOG_EXPORTS_TABLE_NAME = 'catalog-exports-table'
export const BUY_LIST_IMPORTS_TABLE_NAME = 'buy-list-imports-table'
export const BUY_LIST_EXPORTS_TABLE_NAME = 'buy-list-exports-table'
export const UTILITIES_AP_SYSTEMS_EXPORTS_NAME = 'utilities-ap-export-formats-table'
export const AP_SYSTEM_VARIANTS_TABLE_NAME = 'format-variants-table-view'
export const UTILITIES_AP_SYSTEMS_NAME = 'utilities-ap-systems-table'
export const UTILITIES_ORGANISATIONS_TABLE_NAME = 'utilities-organisations-table'
export const ALL_STOCK_ITEMS_TABLE_NAME = 'all-stock-items-table'
export const STOCK_LOCATION_STOCK_ITEMS_TABLE_NAME = 'stock-location-stock-items-table'
export const ALL_TRANSFERS_TABLE_NAME = 'all-transfers-table'
export const ALL_STOCK_LOCATIONS_TABLE_NAME = 'all-stock-locations-table'
export const ALL_USER_UTILITY_TABLE_NAME = 'all-users-utility-table'
export const STOCK_LOCATION_TRANSFERS_TABLE_NAME = 'stock-location-transfers-table'
export const STOCK_ITEM_STOCK_LOCATIONS_TABLE_NAME = 'stock-item-stock-locations-table'
export const TRANSFER_TRANSFER_ITEMS_TABLE_NAME = 'transfer-transfer-items-table'
export const TRADE_RELATIONSHIPS_TABLE_NAME = 'trade-relationships-table'
export const TRADE_RELATIONSHIP_CATALOGS_TABLE_NAME = 'trade-relationship-catalogs-table'
export const STOCK_ITEM_SUBSTITUTE_PRODUCTS_TABLE_NAME = 'stock-item-substitute-products-table'
export const PRODUCT_SUBSTITUTE_PRODUCTS_TABLE_NAME = 'product-substitute-products-table'
export const TRADE_PIPES_TABLE_NAME = 'trade-pipes-table'
export const TRADE_RELATIONSHIP_PIPES_TABLE_NAME = 'trade-relationship-pipes-table'
export const UTILITIES_ORGANISATION_HIERARCHIES_TABLE_NAME = 'utilities-organisation-hierarchies-table'
export const ALL_RECIPES_TABLE_TABLE_NAME = 'all-recipes-table'
export const MENU_RECIPES_TABLE_TABLE_NAME = 'menu-recipes-table'
export const ALL_MENUS_TABLE_TABLE_NAME = 'all-menus-table'
export const RECIPE_MENUS_TABLE_TABLE_NAME = 'recipe-menus-table'
export const RECEIVED_RECEIVING_NOTE_TABLE_NAME = 'received-receiving-note-table'
export const RECIPE_INGREDIENTS_TABLE_NAME = 'recipe-ingredients-table'
export const ALL_INGREDIENTS_TABLE_NAME = 'all-ingredients-table'
export const ALL_PRODUCT_RULES_TABLE_NAME = 'all-product-rules-table'
export const INVOICE_AI_INBOX_TABLE_NAME = 'invoice-ai-inbox-table'
export const UTILITIES_AI_SCANNING_EMAILS_TABLE_NAME = 'utilities-ai-scanning-emails-table'
export const UTILITIES_AI_SCANNING_TASKS_TABLE_NAME = 'utilities-ai-scanning-tasks-table'
export const ALL_APPROVAL_WORKFLOWS_TABLE_NAME = 'all-approval-workflows-table'
export const UTILITIES_PRODUCT_CODES_TABLE_NAME = 'utilities-product-codes-table'
