import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetRequisitionDocument } from '@/graphql/purchasing/generated/getRequisition.generated'
import { RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { OriginType } from '@/modules/requisitions/types/OriginType'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

export function useGetRequisition() {
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { networkStatus, error, data, refetch } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
      sortComments: [{ property: 'created_at', direction: RansackDirection.Asc }],
    },
    skip: !requisitionId,
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const loadingStates = checkNetworkStatus(networkStatus)

  const originType = data?.currentPurchaser?.requisition?.originType || ''
  const originId = data?.currentPurchaser?.requisition?.originId || ''
  const isCustomRequisition = !originType && !loadingStates.loading
  const isAllSupplierRequisition = originType === OriginType.Purchaser && !loadingStates.loading
  const isSingleSupplierRequisition = originType === OriginType.SupplierRelationship && !loadingStates.loading
  const isBuyListRequisition = originType === OriginType.Catalogue && !loadingStates.loading
  const isReOrderRequisition = originType === OriginType.Requisition && !loadingStates.loading
  return {
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    requisition: data?.currentPurchaser?.requisition,
    refetch,
    originType,
    originId,
    isCustomRequisition,
    isAllSupplierRequisition,
    isSingleSupplierRequisition,
    isBuyListRequisition,
    isReOrderRequisition,
  }
}
