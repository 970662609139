import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { UpdateUserLegacyDocument } from '@/graphql/access/generated/updateUserLegacy.generated'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import useSignOut from '@/modules/access/hooks/useSignout'
import Button from '@/modules/shared/components/button/Button'
import { ConfirmDialog } from '@/modules/shared/components/confirm-dialog/ConfirmDialog'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import { displayToast } from '@/modules/shared/components/toast/displayToast'
import { PURCHASE_PLUS_TERMS_AND_CONDITIONS_URL } from '@/modules/shared/constants'
import { NewTabIcon } from '@/modules/shared/icons/NewTabIcon'
import { Themes } from '@/modules/shared/types/Themes'

export default function TermsAndConditions() {
  const { t } = useTranslation()
  const { user } = useSessionUser()
  const [showModal, setShowModal] = useState(false)
  const [showDecline, setShowDecline] = useState(false)
  const [updateUser, { loading: updateUserLoading }] = useMutation(UpdateUserLegacyDocument)
  const [signout, { loading: signoutLoading }] = useSignOut()

  const onDecline = () => {
    signout()
  }

  const onAccept = () => {
    updateUser({
      variables: { input: { id: Number(user?.id), tandcagreedon: new Date().toISOString() } },
      onCompleted(data) {
        if (!data.updateUser.errors?.length) {
          setShowModal(false)
          displayToast({ title: t('general.acceptedAndAgree', 'Accepted and Agreed') })
        }
      },
    })
  }

  useEffect(() => {
    if (user && !user.tandcagreedon) {
      setShowModal(true)
    }
  }, [user])

  return (
    <>
      <Modal showModal={showModal} onCloseModal={() => {}}>
        <Modal.Panel className="z-50 flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-xl">
          <Modal.Title
            title={t('general.termsAndConditionsAgreement', 'Terms and Conditions Agreement')}
            className="py-5"
          />
          <Modal.Body>
            <p className="text-sm text-gray-500">
              <Trans t={t} i18nKey="general.termsAndConditionsAgreementDescription">
                We've recently updated our Terms and Conditions and we need you to read and agree to them in order to
                continue using PurchasePlus. By agreeing below, you are confirming that you have read and accepted our
                updated Terms and Conditions.
              </Trans>
            </p>
            <div className="mt-5 rounded-md border p-4">
              <a
                className="flex items-center text-primary"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="terms-and-conditions-link"
                href={PURCHASE_PLUS_TERMS_AND_CONDITIONS_URL}
              >
                <p> {t('general.pplusTermsAndConditions', 'PurchasePlus Terms and Conditions')} </p>
                <NewTabIcon className="ml-1 size-5" />
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
            <Button
              type="button"
              className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
              onClick={() => setShowDecline(true)}
              data-testid="decline-terms-and-conditions-btn"
            >
              {t('general.decline', 'Decline')}
            </Button>
            <Button
              data-testid="accept-and-agree-btn"
              loading={updateUserLoading}
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              onClick={onAccept}
            >
              {t('general.acceptAndAgree', 'Accept and Agree')}
            </Button>
          </Modal.Footer>
        </Modal.Panel>
      </Modal>
      <ConfirmDialog
        theme={Themes.Error}
        isOpen={showDecline}
        setIsOpen={setShowDecline}
        title={t('general.signoutPPlus', 'Sign Out of PurchasePlus')}
        description={
          <Trans t={t} i18nKey="general.declineTermsAndConditionsWarning">
            By declining our updated Terms and Conditions, you will be signed out of PurchasePlus. We apologise, but you
            cannot use PurchasePlus without accepting and agreeing to our updated Terms and Conditions.
          </Trans>
        }
        btnPrimaryLoading={signoutLoading}
        secondaryButtonLabel={t('general.cancel', 'Cancel')}
        primaryButtonLabel={t('general.signout', 'Sign Out')}
        onPrimaryBtnClick={onDecline}
      />
    </>
  )
}
