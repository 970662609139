import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import { CreateContractCataloguedProductDocument } from '@/graphql/purchasing/generated/createContractCataloguedProduct.generated'
import { GetAvailableProductsQuery, ProductEdge } from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_AVAILABLE_PRODUCTS } from '@/graphql/purchasing/operations/queries/getAvailableProducts'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ProductType } from '@/modules/shared/types/ProductType'

interface CreateContractCataloguedProductProps {
  catalogId?: number
  line?: ProductType
}

export function useCreateContractCataloguedProduct({ catalogId, line }: CreateContractCataloguedProductProps) {
  const mutation = useMutation(CreateContractCataloguedProductDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    update(cache, data) {
      if (!!line && !data.data?.createContractCataloguedProduct.errors?.length) {
        const newProduct = data?.data?.createContractCataloguedProduct?.contractCataloguedProduct
        const queryToCache = {
          query: GET_AVAILABLE_PRODUCTS,
          variables: {
            invoiceId: 0,
            requisitionId: 0,
            catalogueId: catalogId,
            nextGenQuotes: false,
            sort: {
              property: 'created_at',
              direction: 'DESC',
            },
          },
        }
        const availableProductsCache = cache.readQuery(queryToCache)
        const newAvailableProductsCache = produce(availableProductsCache, (draft: GetAvailableProductsQuery) => {
          const { edges } = draft.currentPurchaser?.availableProducts || {}
          if (draft.currentPurchaser && draft.currentPurchaser.availableProducts) {
            draft.currentPurchaser.availableProducts.edges = edges?.map((e) => {
              if (e?.node?.id === line?.product?.id) {
                if (e && e.node) {
                  e.node.catalogued = true
                  e.node.availableQuotes?.push({
                    catalogue: {
                      id: Number(newProduct?.catalogueId),
                      __typename: 'Catalogue',
                    },
                    unitPrice: Number(newProduct?.sellUnitPrice),
                    taxPercentage: Number(newProduct?.sellUnitTaxPercentage),
                    supplier: {
                      id: newProduct?.pricedCatalogue?.supplier?.id || 0,
                      name: newProduct?.pricedCatalogue?.supplier?.name || '',
                      __typename: 'Supplier',
                    },
                    contracted: true,
                  })
                }
              }
              return e
            }) as ProductEdge[]
          }
        })
        cache.writeQuery({ ...queryToCache, data: newAvailableProductsCache })
      }
    },
  })

  return mutation
}
