import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const AccessBaseErrorFragmentDoc = gql`
  fragment AccessBaseError on BaseError {
    extensions {
      code
      status
    }
    message
  }
` as unknown as DocumentNode<Types.AccessBaseErrorFragment, unknown>
